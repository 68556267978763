import { Component, ViewChild, Input } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { BootstrapTabDirective } from 'src/app/directives/bootstrap-tab.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Utilities } from 'src/app/services/utilities';
import { SysproService } from 'src/app/services/syspro.service';
import { localStorageActions } from 'src/app/models/enums';

import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.scss'],
  animations: [fadeInOut]
})
export class WebComponent {

  //isViewActivated = false;
  //isInventoryListActivated = false;
  //isInventoryNSActivated = false;
  //isQuoteViewActivated = false;
  isWebInfoActivated = true;
  isWebReportActivated = false;
  isQuoteWebViewActivated = false;

  isCollapsed = false;

  fragmentSubscription: any;

  //readonly quotesListTab = 'quotes';
  //readonly inventoryListTab = 'inventory';
  //readonly inventoryNSTab = 'nonstock';
  //readonly quoteViewTab = 'quote';
  readonly quoteWebInfoTab = 'webinfo';
  readonly webReportTab = 'webreport';
  readonly quoteWebViewTab = 'webquote';

  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;

  @ViewChild('tab')
  tab: BootstrapTabDirective;

  id: any = "";
    quoteId: string;


  constructor(private router: Router, private route: ActivatedRoute, private configurations: ConfigurationService, private accountService: AccountService) {
  }


  ngOnInit() {
       
    let action = sessionStorage.getItem(localStorageActions.Customer);
    let quoteId = sessionStorage.getItem(localStorageActions.QuoteNumber);
    this.quoteId = quoteId;
    this.isCollapsed = !this.configurations.menuExpanded;

    if (action) {
      this.id = action;
      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor, this.id));
    } else {

      this.fragmentSubscription = this.route.fragment.subscribe(anchor => this.showContent(anchor));
     // this.router.navigate(['quotes']);
    }

  }


  get menuExpanded(): boolean {
    return this.configurations.menuExpanded;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnDestroy() {
    if (this.fragmentSubscription && !this.fragmentSubscription.closed)
      this.fragmentSubscription.unsubscribe();
  }

  showContent(anchor: string, test?: any) {

    this.id = sessionStorage.getItem(localStorageActions.Customer);
    this.quoteId = sessionStorage.getItem(localStorageActions.QuoteNumber);
   
    if (anchor) {
      anchor = anchor.toLowerCase();
      this.setNewTab(anchor);
    }

    this.tab.show(`#${anchor || this.quoteWebInfoTab}Tab`);

  }

  setNewTab(fragment) {
    const activeTab = fragment;

    //this.isInventoryListActivated = activeTab == this.inventoryListTab;
    //this.isInventoryNSActivated = activeTab == this.inventoryNSTab;
    //this.isQuoteViewActivated = activeTab == this.quoteViewTab;
    this.isWebInfoActivated = activeTab == this.quoteWebInfoTab;
    this.isWebReportActivated = activeTab == this.webReportTab;
    this.isQuoteWebViewActivated = activeTab == this.quoteWebViewTab;

    this.router.navigate([], { fragment: activeTab, queryParams: { customer: this.id, quoteId: this.quoteId } });
  }


  isFragmentEquals(fragment1: string, fragment2: string) {

    if (fragment1 == null) {
      fragment1 = '';
    }

    if (fragment2 == null) {
      fragment2 = '';
    }

    return fragment1.toLowerCase() == fragment2.toLowerCase();
  }


  onShowTab(event) {
    const activeTab = event.target.hash.split('#', 2).pop();

    //this.isInventoryListActivated = activeTab == this.inventoryListTab;
    //this.isInventoryNSActivated = activeTab == this.inventoryNSTab;
    //this.isQuoteViewActivated = activeTab == this.quoteViewTab;
    this.isWebInfoActivated = activeTab == this.quoteWebInfoTab;
    this.isWebReportActivated = activeTab == this.webReportTab;
    this.isQuoteWebViewActivated = activeTab == this.quoteWebViewTab;

    this.router.navigate([], { fragment: activeTab });
  }

  get canViewCustomers() {
    return true;
  }


  get canViewWebQuotes() {
    return this.accountService.userHasPermission(Permission.webQuotesPermission);
  }

}
