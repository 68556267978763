import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { localStorageActions } from '../../../models/enums';
import { WebOrder } from '../../../models/web-order.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FowkesOnlineService } from '../../../services/fowkes-online.service';
import { SysproService } from '../../../services/syspro.service';
import { Utilities } from '../../../services/utilities';


@Component({
  selector: 'reports-websales',
  templateUrl: './reports-websales-component.html',
  styleUrls: ['./reports-websales-component.scss']
})
export class ReportsWebSalesComponent implements OnInit {


  private loadingIndicator: boolean;
  show: boolean;
  private selectUndefinedOptionValue: string = "ALL";

  columns: any[] = [];
  rows: WebOrder[] = [];
  rowsCache: WebOrder[] = [];
  customers: any[] = []; // List of customers with aggregated data
  detailRows: WebOrder[] = [];
  selectedCustomer: any;

  selectedQuote: string;
  selectedSalesOrder: string;
  selectedInvoice: string;

  kpis = {
  // Overall KPIs
  totalCustomers: 0, // Total number of distinct customers
  totalOrders: 0,    // Total number of orders across all time
  totalRevenue: 0,   // Total revenue from all orders
  firstTimeOrders: 0, // Total number of first-time orders across all time
  returningOrders: 0, // Total number of returning orders across all time
  customerRetentionRate: 0, // Percentage of customers who placed repeat orders
  repeatCustomerRevenue: 0, // Total revenue from repeat customers across all time
  oneTimeCustomerRevenue: 0, // Total revenue from one-time customers across all time
  repeatCustomerSalesPercentage: 0, // Percentage of total revenue from repeat customers
  averageOrderValue: 0, // Average value of orders across all time

  // Filtered KPIs (based on the selected date range)
  filteredTotalOrders: 0, // Total number of orders in the selected date range
  filteredTotalRevenue: 0, // Total revenue from orders in the selected date range
  filteredFirstTimeOrders: 0, // Number of first-time orders in the selected date range
  filteredReturningOrders: 0, // Number of returning orders in the selected date range
  filteredFirstTimeRevenue: 0, // Revenue from first-time orders in the selected date range
  filteredRepeatRevenue: 0, // Revenue from repeat orders in the selected date range
  filteredFirstTimePercentage: 0, // Percentage of revenue from first-time orders in the date range
  filteredRepeatPercentage: 0, // Percentage of revenue from repeat orders in the date range
};

  @ViewChild('quotesModal')
  quotesModal: ModalDirective;

  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;

  @ViewChild('itsModal')
  itsModal: ModalDirective;

  @ViewChild('orderViewModal')
  orderViewModal: ModalDirective;

  @ViewChild('priceTemplate')
  priceTemplate: TemplateRef<any>;

  @ViewChild('netTemplate')
  netTemplate: TemplateRef<any>;

  @ViewChild('totalNetMerchandiseCell') totalNetMerchandiseCell: TemplateRef<any>;

  @ViewChild('costTemplate')
  costTemplate: TemplateRef<any>;

  @ViewChild('profitTemplate')
  profitTemplate: TemplateRef<any>;

  @ViewChild('gpTemplate')
  gpTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;


  @ViewChild('quoteTemplate')
  quoteTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate')
  nameTemplate: TemplateRef<any>;

  @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('salesOrderTemplate')
  salesOrderTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('previewTemplate')
  previewTemplate: TemplateRef<any>;

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;


  //ranges = {
  //  'All Dates': [moment('2023-01-01').startOf('month'), moment()],
  //  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  //  'This Month to Date': [moment().startOf('month'), moment()],
  //  'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  //  'Current Quarter to Date': [moment().startOf('quarter'), moment()],
  //  'Previous Quarter': [
  //    moment().subtract(1, 'quarter').startOf('quarter'),
  //    moment().subtract(1, 'quarter').endOf('quarter')
  //  ],
  //  'Current Year to Date': [moment().startOf('year'), moment()],
  //  'Previous Year': [
  //    moment().subtract(1, 'year').startOf('year'),
  //    moment().subtract(1, 'year').endOf('year')
  //  ],
  //  'Last 12 Months': [moment().subtract(12, 'months'), moment()],
  //};

  selected: { startDate: Moment, endDate: Moment };
  ranges: { 'This Month': moment.Moment[]; 'Last Month': moment.Moment[]; 'Last 3 Months': moment.Moment[]; };

    totalNetMerchandiseSumm: number;
    totalInvoiceCostSumm: string;
    totalInvoiceSumm: string;
    weborders: WebOrder[];
    sumtotalExcluding: number;
    sumtotalVat: number;
    invoiceNumber: string;
    currentStatus: string;
  repeatCustomers: any[] = [];
  oneTimeCustomers: any[] = [];
  filteredCustomers: any[] = [];
  firstTimeCustomers: any[] = [];
  returnCustomers: any[] = [];
  

  constructor(private alertService: AlertService, private sysproService: SysproService, private router: Router, private route: ActivatedRoute, private fowkesOnline: FowkesOnlineService) {
  }


  private setData() {
    this.columns = [
      { prop: 'id', name: 'Order Id', width: 50 },
      { prop: 'created', name: 'Date', width: 100, cellTemplate: this.dateTemplate },
      { prop: 'placed_id', name: 'Waybill', width: 100, cellTemplate: this.selectTemplate },
      { prop: 'user_first_name', name: 'Customer Name', width: 150, cellTemplate: this.nameTemplate },
      { prop: 'grand_total', name: 'Total', width: 90, cellTemplate: this.totalTemplate, cellClass: "right" },
      { prop: 'internalStatus', name: 'Status', width: 100 },
      { prop: 'quoteNumber', name: 'Quote Number', width: 120, cellTemplate: this.quoteTemplate },
      { prop: 'salesOrder', name: 'SalesOrder', width: 100, cellTemplate: this.salesOrderTemplate },
      { prop: 'invoice', name: 'ITS', width: 100, cellTemplate: this.invoiceTemplate },
      { prop: 'user_email', name: 'Customer Email', width: 150 },
      { prop: 'user_contact_number', name: 'Customer Tel', width: 90 }
    ];

  }

  summaryTotal(cells: number[]): string {
    const filteredCells = cells.filter(cell => !!cell);
    return filteredCells.reduce((sum, cell) => (sum += cell), 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  ngAfterViewInit() { 
    this.setData();
  }


  ngOnInit() {
    this.setData();

    const currentYear = moment().year(); // Get the current year
    const startYear = 2022; // Start from 2022
    const yearRanges = {};

    // Generate year ranges dynamically
    for (let year = startYear; year <= currentYear; year++) {
      yearRanges[`${year}`] = [moment(`${year}-01-01`).startOf('year'), moment(`${year}-12-31`).endOf('year')];
    }

    // Define the new ranges
    this.ranges = {     
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
      'Last 3 Months': [
        moment().subtract(3, 'months').startOf('month'),
        moment().endOf('month'),
      ],
      ...yearRanges, // Append dynamically generated year ranges
    };

    this.selected = {
      startDate: moment('2022-01-01').startOf('month'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }  
    this.loadData();
  }


    loadData() {
      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
      this.sysproService.getAllWebOrders().subscribe(x => this.processOrders(x), error => this.onDataLoadFailed(error));
  }

  refresh() {
    this.selected = {
      startDate: moment('2022-01-01').startOf('month'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }

    this.customers = [];
    this.filteredCustomers = [];
    this.firstTimeCustomers = [];
    this.repeatCustomers = [];
    this.oneTimeCustomers = [];
    this.returnCustomers = [];

    this.loadData();
  }

  processOrders(orders: WebOrder[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.rowsCache = orders;
    
    // Filter out cancelled orders
    const filteredOrders = orders.filter(order => order.internalStatus !== "CANCELLED");

    //// Filter by date range
    //const filteredOrders = nonCancelledOrders.filter(order =>
    //  moment(order.created).isBetween(this.selected.startDate, this.selected.endDate, 'day', '[]')
    //);

    // Group by customer
    const customerGroups = filteredOrders.reduce((acc, curr) => {
      const key = curr.user_email;
      if (!acc[key]) {
        acc[key] = {
          email: curr.user_email,
          name: `${curr.user_first_name} ${curr.user_last_name}`,
          orderCount: 0,
          totalRevenue: 0
        };
      }
      acc[key].orderCount++;
      acc[key].totalRevenue += curr.grand_total || 0;
      return acc;
    }, {});

    // Flatten customer groups into an array
    this.customers = Object.values(customerGroups);

    // Set initial rows for display
    this.rows = [...this.customers];

    // Calculate repeat and one-time customers
    this.repeatCustomers = Object.values(customerGroups).filter((group: any) => group.orderCount > 1);
    const repeatCustomerRevenue = this.repeatCustomers.reduce((sum, customer: any) => sum + customer.totalRevenue, 0);

    this.oneTimeCustomers = Object.values(customerGroups).filter((group: any) => group.orderCount === 1);
    const oneTimeCustomerRevenue = this.oneTimeCustomers.reduce((sum, customer: any) => sum + customer.totalRevenue, 0);

    // Calculate KPIs
    const firstTimeOrders = this.oneTimeCustomers.length;
    const returningOrders = filteredOrders.length - firstTimeOrders;

    this.kpis.totalCustomers = this.customers.length;
    this.kpis.totalOrders = filteredOrders.length;
    this.kpis.totalRevenue = this.customers.reduce((sum, customer: any) => sum + customer.totalRevenue, 0);
    this.kpis.averageOrderValue = this.kpis.totalRevenue / this.kpis.totalOrders || 0;
    this.kpis.firstTimeOrders = firstTimeOrders;
    this.kpis.returningOrders = returningOrders;

    // Update retention rate
    this.kpis.customerRetentionRate = (this.repeatCustomers.length / this.kpis.totalCustomers) * 100 || 0;

    // New revenue breakdown KPIs
    this.kpis.repeatCustomerRevenue = repeatCustomerRevenue;
    this.kpis.oneTimeCustomerRevenue = oneTimeCustomerRevenue;

    // New percentage of repeat customer sales
    this.kpis.repeatCustomerSalesPercentage =
      (repeatCustomerRevenue / this.kpis.totalRevenue) * 100 || 0;
  }

  filterOrders() {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    // Filter out cancelled orders
    const filteredOrders = this.rowsCache.filter(order => order.internalStatus !== "CANCELLED");

    // 1. Filter orders by the selected date range
    const dateFilteredOrders = filteredOrders.filter(order =>
      moment(order.created).isBetween(this.selected.startDate, this.selected.endDate, 'day', '[]')
    );

    // 2. Identify first-time and return customers
    const customerGroups = filteredOrders.reduce((acc, order) => {
      const key = order.user_email;
      if (!acc[key]) {
        acc[key] = {
          email: order.user_email,
          name: `${order.user_first_name} ${order.user_last_name}`,
          firstOrderDate: moment(order.created), // Track the first-ever order date
          totalRevenue: 0,
          orderCount: 0,
        };
      }
      acc[key].orderCount++;
      acc[key].totalRevenue += order.grand_total || 0;
      acc[key].firstOrderDate = moment.min(acc[key].firstOrderDate, moment(order.created)); // Keep the earliest date
      return acc;
    }, {});

    // Filter customers by date range logic
    const filteredCustomerGroups = dateFilteredOrders.reduce((acc, order) => {
      const key = order.user_email;
      if (!acc[key]) {
        acc[key] = {
          email: order.user_email,
          name: `${order.user_first_name} ${order.user_last_name}`,
          orderCount: 0,
          totalRevenue: 0,
          firstOrderDate: customerGroups[key].firstOrderDate, // Retrieve the first-ever order date
        };
      }
      acc[key].orderCount++;
      acc[key].totalRevenue += order.grand_total || 0;
      return acc;
    }, {});

    // 3. Categorize first-time and return customers
    this.firstTimeCustomers = Object.values(filteredCustomerGroups).filter(
      (customer: any) => customer.firstOrderDate.isBetween(this.selected.startDate, this.selected.endDate, 'day', '[]')
    );

    this.returnCustomers = Object.values(filteredCustomerGroups).filter(
      (customer: any) => customer.firstOrderDate.isBefore(this.selected.startDate)
    );

    // 4. Flatten customer groups into an array
    this.filteredCustomers = Object.values(filteredCustomerGroups);

    // 5. Calculate filtered KPIs
    this.kpis.filteredTotalOrders = dateFilteredOrders.length;
    this.kpis.filteredTotalRevenue = this.filteredCustomers.reduce((sum, customer: any) => sum + customer.totalRevenue, 0);
    this.kpis.filteredFirstTimeOrders = this.firstTimeCustomers.length;
    this.kpis.filteredReturningOrders = this.returnCustomers.length;

    this.kpis.filteredFirstTimeRevenue = this.firstTimeCustomers.reduce((sum, customer: any) => sum + customer.totalRevenue, 0);
    this.kpis.filteredRepeatRevenue = this.returnCustomers.reduce((sum, customer: any) => sum + customer.totalRevenue, 0);

    // 6. Calculate percentages
    this.kpis.filteredFirstTimePercentage =
      (this.kpis.filteredFirstTimeRevenue / this.kpis.filteredTotalRevenue) * 100 || 0;
    this.kpis.filteredRepeatPercentage =
      (this.kpis.filteredRepeatRevenue / this.kpis.filteredTotalRevenue) * 100 || 0;

    // 7. Update the rows for display
    this.rows = [...this.filteredCustomers];
  }


  onSearchChanged(value: string) {
   if (value !== "") {
      this.rows = this.customers.filter(customer =>
        Utilities.searchArray(
          value,
          false,
          customer.name,
          customer.email,
          customer.orderCount.toString(),
          customer.totalRevenue.toFixed(2) // Convert numbers to strings for matching
        )
      );
    } else {
      this.rows = [...this.customers]; // Reset to all customers if no search term
    }
  }

  // Trigger modal and fetch customer orders
  viewCustomerOrders(customer: any): void {
    this.selectedCustomer = customer;

    // Filter orders for the selected customer
    this.detailRows = this.rowsCache.filter(order => order.user_email.trim() == customer.email.trim());

    if (this.detailRows && this.detailRows.length > 0)  {
      if (!this.orderViewModal) {
        setTimeout(() => {
          if (this.orderViewModal) {
            this.orderViewModal.show();
          }
        });
      }
      else {
        this.orderViewModal.show();
      }
    }

  }

  // Hide modal
  orderViewModalHide(): void {
    this.selectedCustomer = "";
    this.detailRows = null;
    this.orderViewModal.hide();
  }


  viewQuote(row: WebOrder) {
    this.setLocalCustomer("FBWEB", row.quoteNumber);
    this.router.navigate(['../../quotes'], { fragment: 'quote', queryParams: { customer: "FBWEB" } });
  }


  previewQuote(row: WebOrder) {
    this.currentStatus = row.internalStatus;

    this.invoiceNumber = row.invoice;
    this.setLocalCustomer("FBWEB", row.quoteNumber);

    if (!!this.selectedQuote) {
      if (!this.quotesModal) {
        setTimeout(() => {
          if (this.quotesModal) {
            this.quotesModal.show();
          }
        });
      }
      else {
        this.quotesModal.show();
      }
    }
  }

  quotesModalHide() {
    this.selectedQuote = null;
    this.quotesModal.hide();
  }

  gotToQuote() {
    this.router.navigate(['../../quotes'], { fragment: 'quote', queryParams: { customer: "FBWEB" } });
  }

  previewSalesOrder(row: WebOrder) {

    this.currentStatus = row.internalStatus;
    this.invoiceNumber = row.invoice;

    this.selectedSalesOrder = row.salesOrder;
    if (sessionStorage.getItem(localStorageActions.Customer) != "FBWEB") {
      sessionStorage.setItem(localStorageActions.Customer, "FBWEB");
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != row.salesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrder);
    }

    if (!!this.selectedSalesOrder) {
      if (!this.salesOrderModal) {
        setTimeout(() => {
          if (this.salesOrderModal) {
            this.salesOrderModal.show();
          }
        });
      }
      else {
        this.salesOrderModal.show();
      }
    }
  }

  salesOrderModalHide() {
    this.selectedSalesOrder = null;
    this.salesOrderModal.hide();
  }


  viewSalesOrder() {

    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: "FBWEB" } });
  }


  previewITS(row: WebOrder) {

    this.selectedInvoice = row.invoice;
    this.currentStatus = row.internalStatus;
    this.invoiceNumber = row.invoice;

    if (sessionStorage.getItem(localStorageActions.Customer) != "FBWEB") {
      sessionStorage.setItem(localStorageActions.Customer, "FBWEB");
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != row.salesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrder);
    }

    if (sessionStorage.getItem(localStorageActions.ITSInvoiceNumber) != row.invoice) {
      sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoice);
    }

    if (!!this.selectedInvoice) {
      if (!this.itsModal) {
        setTimeout(() => {
          if (this.itsModal) {
            this.itsModal.show();
          }
        });
      }
      else {
        this.itsModal.show();
      }
    }
  }

  itsModalHide() {
    this.selectedInvoice = null;
    if (this.itsModal) {
      this.itsModal.hide();
    }
  }


  gotToITS() {

    this.router.navigate(['../../reports'], { fragment: 'invoicestatus', queryParams: { customer: "FBWEB" } });
  }

  setLocalCustomer(customer: string, quoteNumber: string) {

    this.selectedCustomer = customer;
    this.selectedQuote = quoteNumber;
    if (sessionStorage.getItem(localStorageActions.QuoteNumber) != quoteNumber) {
      sessionStorage.setItem(localStorageActions.QuoteNumber, quoteNumber);
    }
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
   
  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  toggle() {
    this.show = true;
    this.filterOrders();
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.filterOrders();
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }
   
    this.show = false;
    this.filterOrders();
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.show = false;
    this.filterOrders();

  }


  exportHeaders() {

    if (!this.rows || this.rows.length === 0) {
      console.warn('No data available for export.');
      return;
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ['Customer Name', 'Email', 'Order Count', 'Total Revenue'],
    };

    const exportData = this.customers.map(row => ({
      'Customer Name': row.name,
      Email: row.email,
      'Order Count': row.orderCount,
      'Total Revenue': row.totalRevenue.toFixed(2), // Ensure consistent number formatting
    }));

    new ngxCsv(exportData, 'CustomerInsights', options);
  }


  exportCustomerOrders(): void {
    if (!this.detailRows || this.detailRows.length === 0) {
      console.warn('No orders available for export.');
      return;
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false, // Disable column headers since we include custom headers
      showTitle: false,
      useBom: true,
    };

    // Header rows for customer details
    const headerRows = [
      { field: 'Customer Name', value: this.selectedCustomer.name },
      { field: 'Email', value: this.selectedCustomer.email },
      { field: 'Order Count', value: this.selectedCustomer.orderCount },
      { field: 'Total Revenue', value: `R ${this.selectedCustomer.totalRevenue.toFixed(2)}` },
      {}, // Empty row for separation
      { field: 'Order ID', value: 'Date', Status: 'Status', Quote: 'Quote Number', Sales: 'Sales Order', Invoice: 'Invoice', Total: 'Total' },
    ];

    // Map order data for export
    const exportData = this.detailRows.map(order => ({
      'Order ID': order.placed_id,
      Date: moment(order.created).format('YYYY-MM-DD'),
      'Status': order.internalStatus,
      'Quote Number': order.quoteNumber,
      'Sales Order': order.salesOrder,
      Invoice: order.invoice,
      Total: order.grand_total || '0',
    }));

    // Combine header rows and order data
    const csvData = [...headerRows, ...exportData];

    // Generate the CSV file
    new ngxCsv(csvData, `${this.selectedCustomer.name}_WebOrders`, options);
  }


  exportAllWebOrders(): void {
    if (!this.rowsCache || this.rowsCache.length === 0) {
      console.warn('No orders available for export.');
      return;
    }
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: ['Customer Name', 'Email', 'Date', 'Order ID', 'Status', 'Quote Number', 'Invoice', 'Total'],
    };

    const exportData = this.rowsCache.map(order => ({
      'Customer Name': `${order.user_first_name} ${order.user_last_name}`,
      Email: order.user_email,
      Date: moment(order.created).format('YYYY-MM-DD'),
      'Order ID': order.placed_id,
      'Status': order.internalStatus,
      'Quote Number': order.quoteNumber,
      Invoice: order.invoice,
      Total: order.grand_total || '0',
    }));

    new ngxCsv(exportData, 'AllCustomerWebOrders', options);
  }

}
