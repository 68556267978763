
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,

  MatTreeModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatDividerModule,
  MatStepperModule,
  MatBottomSheetModule,
  MatBadgeModule
} from '@angular/material';
//import { ngCurrency } from 'ng-currency';

import { CdkStepperModule } from '@angular/cdk/stepper';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastaModule } from 'ngx-toasta';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDateRangePickerModule } from 'ngx-daterangepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TreeviewModule } from 'ngx-treeview';
/*import { NgxEditorModule } from 'ngx-editor';*/
import { ChartsModule } from 'ng2-charts';
import { Quagga} from 'quagga';


import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { ThemeManager } from './services/theme-manager';
import { LocalStoreManager } from './services/local-store-manager.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { FowkesOnlineService } from './services/fowkes-online.service';
import { FowkesOnlineEndpoint } from './services/fowkes-online-endpoint.service';
import { SysproService } from './services/syspro.service';
import { SysproEndpoint } from './services/syspro-endpoint.service';
import { ReportService } from './services/report.service';
import { ReportEndpoint } from './services/report-endpoint.service';
import { PrinterService } from './services/printer.service';
import { PrinterEndpoint } from './services/printer-endpoint.service';
import { ExternalHTTPService } from './services/external-api.service';

import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
//import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { BootstrapSelectDirective } from './directives/bootstrap-select.directive';
import { BootstrapDatepickerDirective } from './directives/bootstrap-datepicker.directive';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { GroupByPipe } from './pipes/group-by.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import PdfViewerModule

import { AppComponent } from './components/app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { CustomersComponent } from './components/customers/customers.component';
import { CustomerPortalComponent } from './components/customer-portal/customer-portal.component';

import { ProductsComponent } from './components/products/products.component';
import { VideosComponent } from './components/products/products.videos.component';
import { BrandsComponent } from './components/products/products.brands.component';
import { ManageImagesComponent } from './components/products/products-manage-images.component';
import { MissingImagesComponent } from './components/products/products-missing-images.component';
import { ManageDataSheetsComponent } from './components/products/products-manage-datasheets.component';
import { NewVideosComponent } from './components/products/products.newvideos.component';
import { StockCodesComponent } from './components/products/products.stockcodes.component';
import { ProductsNewComponent } from './components/products/products-new.component';
import { ProductsCatalogueComponent } from './components/products/products-catalogue.component';
import { ProductsEditCategoriesComponent } from './components/products/products-editcategories.component';
import { ProductsEditMetaTagsComponent } from './components/products/products-edit-metatags.component';
import { ProductsCatagoryCatalogueComponent } from './components/products/products-category-catalogue.component';
import { ProductsAdvertsComponent } from './components/products/products.adverts.component';
import { ProductsClearanceComponent } from './components/products/products.clearance.component';
import { ProductsCalculatorComponent } from './components/products/products-calculator.component';



import { ITSSettingsComponent } from './components/settings/admin-components/settings.ITS.component';
import { ITSUserSettingsComponent } from './components/settings/admin-components/settings.ITS-Users.component';
import { BranchSettingsComponent } from './components/settings/admin-components/settings.branch.component';
import { PrinterSettingsComponent } from './components/settings/admin-components/settings.printer.component';
import { InfoSettingsComponent } from './components/settings/admin-components/settings-info.component';
import { InfoDocsComponent } from './components/settings/admin-components/settings-info-docs.component';
import { SettingsServicesComponent } from './components/settings/admin-components/settings.services.component';
import { WhatsappInboxComponent } from './components/settings/admin-components/whatsapp-inbox.component';
import { WhatsappSalesInboxComponent } from './components/settings/admin-components/whatsapp-sales-inbox.component';

import { OrdersComponent } from './components/orders/orders.component';
import { SalesOrdersComponent } from './components/salesorders/salesorders.component';
import { PurchaseOrdersComponent } from './components/purchaseorders/purchaseorders.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { WebComponent } from './components/web/web.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { BannerDemoComponent } from './components/controls/banner-demo.component';
import { TodoDemoComponent } from './components/controls/todo-demo.component';
import { StatisticsDemoComponent } from './components/controls/statistics-demo.component';
import { NotificationsViewerComponent } from './components/controls/notifications-viewer.component';
import { SysproRepViewerComponent } from './components/controls/syspro-rep-viewer.component';
import { SearchBoxComponent } from './components/controls/search-box.component';
import { UserInfoComponent } from './components/controls/user-info.component';
import { UserPreferencesComponent } from './components/controls/user-preferences.component';
import { UsersManagementComponent } from './components/controls/users-management.component';
import { RolesManagementComponent } from './components/controls/roles-management.component';
import { RoleEditorComponent } from './components/controls/role-editor.component';
import { SysproCustomersManagementComponent } from './components/controls/syspro-customers-management.component';
import { CustomerSearchComponent } from './components/quotes/copy-quote/customers-search.component';
import { CustomerViewComponent } from './components/quotes/copy-quote/customers-view.component';
import { CustomerCartComponent } from './components/quotes/copy-quote/customers-cart.component';
import { SysproCustomersMovementsComponent } from './components/controls/syspro-customers-movements.component';
import { SysproCustomersPaymentsComponent } from './components/controls/syspro-customers-payments.component';
import { SysproCustomerStatementsComponent } from './components/customers/statements/customer-statements.component';
import { SysproCustomerToolrepairsComponent } from './components/customers/tool-repairs/customer-tool-repairs.component';
import { BankImportComponent } from './components/customers/bank-tools/bank-import.component';
import { BankImportReportComponent } from './components/customers/bank-tools/bank-import-report.component';
import { BankImportManagementComponent } from './components/customers/bank-tools/bank-import-management.component';
import { BankExportComponent } from './components/customers/bank-tools/bank-export.component';
import { CoreCustomerSettingsComponent } from './components/customers/core-customers/core-customer-settings.component';
import { CoreCustomerManagementComponent } from './components/customers/core-customers/core-customer-management.component';
import { CoreCustomerAdminComponent } from './components/customers/core-customers/core-customer-admin.component';
import { SysproCustomersViewPortalComponent } from './components/customer-portal/syspro-customers-view-portal.component';
import { SysproInventoryManagementComponent } from './components/controls/syspro-inventory-management.component';
import { SysproInventoryListComponent } from './components/controls/syspro-inventory-list.component';
import { SysproInventoryNonStockComponent } from './components/controls/syspro-inventory-nonstock.component';
import { SysproCartManagementComponent } from './components/controls/syspro-cart-management.component';
import { SysproQuoteViewComponent } from './components/controls/syspro-quote-view.component';
import { SysproQuoteManagementComponent } from './components/controls/syspro-quote-management.component';
import { SysproQuoteManagementWebComponent } from './components/controls/syspro-quote-management-web.component';
import { SysproWebOrdersManagementComponent } from './components/controls/syspro-weborders-management.component';
import { SysproCustomerViewComponent } from './components/controls/syspro-customer-view.component';
import { SysproCustomerPreviewComponent } from './components/controls/syspro-customer-preview.component';
import { SysproInventoryPreviewComponent } from './components/controls/syspro-inventory-preview.component';
import { SysproCustomerNotesComponent } from './components/controls/syspro-customer-notes.component';
import { SettingsConfigurationComponent } from './components/controls/settings-configuration.component';
import { AddressComponent } from './components/templates/address.component';
import { SysproSalesOrderViewComponent } from './components/controls/syspro-salesorder-view.component';
import { SysproSalesOrderManagementComponent } from './components/controls/syspro-salesorder-management.component';
import { SysproPurchaseOrderManagementComponent } from './components/controls/syspro-purchaseorder-management.component';
import { SysproInvoiceViewComponent } from './components/controls/syspro-invoice-view.component';
import { SysproPurchaseOrderViewComponent } from './components/controls/syspro-purchaseorder-view.component';
import { ReportsComponent } from './components/reports/reports.component';
import { RepairsComponent } from './components/repairs/repairs.component';
import { ToolRepairsDashboardComponent } from './components/repairs/tool-repairs/tool-repairs-dashboard.component';
import { ReportsInvoiceReprintComponent } from './components/reports/report-components/reports-invoice-reprint-component';
import { ReportsInvoiceStatusComponent } from './components/reports/report-components/reports-invoice-status-component';
import { ReportsSalesComponent } from './components/reports/report-components/reports-sales-component';
import { ReportsDebtorsReleaseComponent } from './components/reports/report-components/reports-debtors-release-component';
import { ReportsCreditorsComponent } from './components/reports/report-components/reports-creditors-component';
import { ReportsQuoteSalesComponent } from './components/reports/report-components/reports-quote-sales.component';
import { ReportsInvoiceTrackingPurgeComponent } from './components/reports/report-components/reports-invoice-tracking-purge-component';
import { ReportsCreditNotesSalesComponent } from './components/reports/report-components/reports-credit-notes-sales-component';
import { ReportsInvoiceTrackingComponent } from './components/reports/report-components/reports-invoice-tracking.component';
import { ReportsInvoiceTrackingControlComponent } from './components/reports/report-components/reports-invoice-tracking-control.component';
import { ReportsInvoiceTrackingDeliveryComponent } from './components/reports/report-components/reports-invoice-tracking-delivery.component';
import { ReportsInvoiceTrackingCollectionsComponent } from './components/reports/report-components/reports-invoice-tracking-collections.component';
import { ReportsProductivityComponent } from './components/reports/report-components/reports-productivity-component';
import { ReportsProductCatalogueComponent } from './components/reports/report-components/reports-product-catalogue-usage.component';
import { ReportsInventoryMovementsComponent } from './components/reports/report-components/reports-inventory-movements.component';
import { ReportsInventoryAdjustmentsComponent } from './components/reports/report-components/reports-inventory-adjustments.component';
import { ReportsWebSalesComponent } from './components/web/components/reports-websales-component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { TrackingDriversComponent } from './components/tracking/tracking-components/tracking-drivers.component';
import { TrackingVehiclesComponent } from './components/tracking/tracking-components/tracking-vehicles.component';
import { TrackingCagesComponent } from './components/tracking/tracking-components/tracking-cages.component';
import { TrackingAreasComponent } from './components/tracking/tracking-components/tracking-areas.component';
import { TrackingInvoiceStatusComponent } from './components/tracking/tracking-components/tracking-invoice-status-component';
import { TrackingPrintLabelsComponent } from './components/tracking/tracking-components/tracking-print-labels.component';
import { TrackingDriversReportComponent } from './components/tracking/tracking-components/tracking-drivers-report-component';
import { TrackingFingerPrintComponent } from './components/tracking/fingertprint-components/tracking-fingerprint.component';
import { PodComponent } from './components/pod/pod.component';
import { PodSyncComponent } from './components/pod/pod-components/pod-sync.component';
import { PodFileSyncComponent } from './components/pod/pod-components/pod-file-sync.component';
import { PodSearchComponent } from './components/pod/pod-components/pod-search.component';
import { PodEditBatchComponent } from './components/pod/pod-components/pod-editbatch.component';
import { PodReconcileComponent } from './components/pod/pod-components/pod-reconcile.component';
import { PodSearchMissingComponent } from './components/pod/pod-components/pod-search-missing.component';
import { PodViewComponent } from './components/pod/pod-components/pod-view.component';
import { DocumentsComponent } from './components/documents/document.component';
import { ViewDocumentsComponent } from './components/documents/document-components/document-view.component';
import { EditCategoriesComponent } from './components/documents/document-components/document-editcategories.component';
import { ParentComponent } from './components/category-template/parent.component';
import { ChildComponent } from './components/category-template/child-item/child-item.component';

import { TreeDynamicExample } from './components/category-template/tree-dynamic-example';




@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    /*NgxEditorModule,*/
    NgxDatatableModule,
    ToastaModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    NgxDateRangePickerModule,
    TreeviewModule.forRoot(),
    ChartsModule,
    PdfViewerModule,
    AppRoutingModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CustomersComponent,
    CustomerPortalComponent,
    ProductsComponent,
    WebComponent,
    VideosComponent,
    BrandsComponent,
    ManageImagesComponent,
    ManageDataSheetsComponent,
    MissingImagesComponent,
    NewVideosComponent,
    ProductsNewComponent,
    ProductsEditCategoriesComponent,
    ProductsEditMetaTagsComponent,
    ProductsCatagoryCatalogueComponent,
    ProductsCatalogueComponent,
    ProductsAdvertsComponent,
    ProductsClearanceComponent,
    ProductsCalculatorComponent,
    ITSSettingsComponent,
    ITSUserSettingsComponent,
    BranchSettingsComponent,
    PrinterSettingsComponent,
    WhatsappInboxComponent,
    WhatsappSalesInboxComponent,
    OrdersComponent,
    SalesOrdersComponent,
    PurchaseOrdersComponent,
    QuotesComponent,
    SettingsComponent,
    SettingsServicesComponent,
    CoreCustomerManagementComponent,
    CoreCustomerAdminComponent,
    UserInfoComponent,
    UsersManagementComponent,
    UserPreferencesComponent,
    RolesManagementComponent,
    RoleEditorComponent,
    SysproRepViewerComponent,
    SysproCustomersManagementComponent,
    CustomerSearchComponent,
    CustomerViewComponent,
    CustomerCartComponent,
    SysproCustomersMovementsComponent,
    SysproCustomersPaymentsComponent,
    SysproCustomerViewComponent,
    SysproCustomerPreviewComponent,
    SysproCustomerNotesComponent,
    SysproCustomersViewPortalComponent,
    SysproCustomerStatementsComponent,
    SysproCustomerToolrepairsComponent,
    BankImportComponent,
    BankExportComponent,
    BankImportReportComponent,
    BankImportManagementComponent,
    SettingsConfigurationComponent,
    InfoSettingsComponent,
    InfoDocsComponent,
    CoreCustomerSettingsComponent,
    SysproInventoryManagementComponent,
    SysproInventoryListComponent,
    SysproInventoryNonStockComponent,
    SysproInventoryPreviewComponent,
    SysproCartManagementComponent,
    SysproQuoteManagementComponent,
    SysproQuoteManagementWebComponent,
    SysproQuoteViewComponent,
    SysproWebOrdersManagementComponent,
    SysproSalesOrderViewComponent,
    SysproSalesOrderManagementComponent,
    SysproInvoiceViewComponent,
    SysproPurchaseOrderManagementComponent,
    SysproPurchaseOrderViewComponent,
    ReportsComponent,
    RepairsComponent,
    ToolRepairsDashboardComponent,
    ReportsInvoiceReprintComponent,
    ReportsInvoiceStatusComponent,
    ReportsSalesComponent,
    ReportsDebtorsReleaseComponent,
    ReportsCreditorsComponent,
    ReportsQuoteSalesComponent,
    ReportsInvoiceTrackingPurgeComponent,
    ReportsInvoiceTrackingControlComponent,
    ReportsCreditNotesSalesComponent,
    ReportsInvoiceTrackingComponent,
    ReportsInvoiceTrackingDeliveryComponent,
    ReportsInvoiceTrackingCollectionsComponent,
    ReportsProductivityComponent,
    ReportsProductCatalogueComponent,
    ReportsInventoryMovementsComponent,
    ReportsInventoryAdjustmentsComponent,
    ReportsWebSalesComponent,
    TrackingComponent,
    TrackingDriversComponent,
    TrackingVehiclesComponent,
    TrackingCagesComponent,
    TrackingAreasComponent,
    TrackingInvoiceStatusComponent,
    TrackingPrintLabelsComponent,
    TrackingDriversReportComponent,
    TrackingFingerPrintComponent,
    PodComponent,
    PodSearchComponent,
    PodEditBatchComponent,
    PodReconcileComponent,
    PodSyncComponent,
    PodFileSyncComponent,
    PodViewComponent,
    PodSearchMissingComponent,
    DocumentsComponent,
    ViewDocumentsComponent,
    EditCategoriesComponent,
    AddressComponent,
    AboutComponent,
    StockCodesComponent,
    NotFoundComponent,
    NotificationsViewerComponent,
    SearchBoxComponent,
    StatisticsDemoComponent, TodoDemoComponent, BannerDemoComponent,
    EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,
    BootstrapToggleDirective,
    BootstrapSelectDirective,
    BootstrapDatepickerDirective,
    GroupByPipe,
    SafePipe,
    ParentComponent,
    ChildComponent,
    TreeDynamicExample
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    AlertService,
    ThemeManager,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint, 
    FowkesOnlineService,
    FowkesOnlineEndpoint,
    SysproService,
    SysproEndpoint,
    ReportService,
    ReportEndpoint,
    PrinterService,
    PrinterEndpoint,
    ExternalHTTPService,
    LocalStoreManager,
    EndpointFactory,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
