
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { isBetween } from 'ngx-bootstrap/chronos/utils/date-compare';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { CartHeader } from 'src/app/models/cart-header.model';
import { localStorageActions } from 'src/app/models/enums';
import { Inventory } from 'src/app/models/inventory.model';
import { QuoteList } from 'src/app/models/quote-list.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CoreAudit } from '../../models/core-audit.model';
import { InvoiceTrackingStatus } from '../../models/invoice-tracking-status.model';
import { Outbox } from '../../models/wapp-inbox.model';
import { WebLineItem } from '../../models/web-line-item.model';
import { WebOrder } from '../../models/web-order.model';
import { WebPayment } from '../../models/web-payment.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { ExternalHTTPService } from '../../services/external-api.service';
import { FowkesOnlineService } from '../../services/fowkes-online.service';
import { ReportService } from '../../services/report.service';
import { SysproService } from '../../services/syspro.service';
import { Utilities } from '../../services/utilities';
import { SysproCustomerViewComponent } from './syspro-customer-view.component';



@Component({
  selector: 'syspro-weborders-management',
  templateUrl: './syspro-weborders-management.component.html',
  styleUrls: ['./syspro-weborders-management.component.scss']
})
export class SysproWebOrdersManagementComponent implements OnInit, AfterViewInit {

  private cart: CartHeader = new CartHeader();

  @ViewChild(DaterangepickerComponent) picker: DaterangepickerComponent;

  ranges = {
    'All Dates': [moment('2023-01-01').startOf('month'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
  };


  selected: { startDate: Moment, endDate: Moment };

  show: boolean;

  columns: any[] = [];
  rows: WebOrder[] = [];
  rowsCache: WebOrder[] = [];
  rowsInclCancelled: WebOrder[] = [];
  dateRows: WebOrder[] = [];
  loadingIndicator: boolean;

  @Input()
  isViewOnly: boolean;

  @ViewChild('quotesModal')
  quotesModal: ModalDirective;

  @ViewChild('salesOrderModal')
  salesOrderModal: ModalDirective;

  @ViewChild('itsModal')
  itsModal: ModalDirective;

  @ViewChild('weborderDetailModal')
  weborderDetailModal: ModalDirective;

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate')
  selectTemplate: TemplateRef<any>;


  @ViewChild('quoteTemplate')
  quoteTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate')
  nameTemplate: TemplateRef<any>;

    @ViewChild('totalTemplate')
  totalTemplate: TemplateRef<any>;

  @ViewChild('salesOrderTemplate')
  salesOrderTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate')
  invoiceTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate')
  dateTemplate: TemplateRef<any>;

    @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('previewTemplate')
  previewTemplate: TemplateRef<any>;

    @ViewChild('editorModal')
    editorModal: ModalDirective;

    @ViewChild('customerEditor')
    customerEditor: SysproCustomerViewComponent;
    inventoryList: Inventory[];


  @Input()
  isManagement: boolean;

  @Input()
  isCart: boolean;
    cartId: string;
    id: string;
    gridHeight: number = 0;
  TotalItems: number = 0;
  TotalLines: number = 0;
    TotalExcl: number = 0;
    TotalDiscount: number = 0;
    TotalExclAfterDiscount: number = 0;
    TotalVat: number = 0;
    TotalIncl: number = 0;
    isSaving: boolean;
    selectedItems: any;
    isNavigating: boolean;
    cartSaved: boolean;
    quoteNumber: any;
  quotes: QuoteList[];

  check30Days: boolean;
  searchType: string = "";
  searchText: string = "";
  searchResults: QuoteList[] = [];
  searchResultSelected: string = "";
  @ViewChild('searchResultSelector')
  private searchResultSelector;
  private selectUndefinedOptionValue: string = "Please Select";

  @ViewChild('statusListSelector')
  private statusListSelector;
  private allstatuses: string = "All";
  selectedStatus: string = "";

  @ViewChild('searchInput')
  searchInput: ElementRef;
  searchValue: string = "";
  smartsearch: string = "";

  selectedCustomer: string;
  selectedQuote: string;
  selectedSalesOrder: string;
  selectedInvoice: string;
  weborders: WebOrder[];
  webOrder: WebOrder;
  webOrderLines: WebLineItem[] = [];
  webOrderPayment: WebPayment[] = [];
    sumtotalExcluding: number;
    sumtotalIncluding: number;
  sumtotalVat: number;

  chkAll: boolean;
  chkQ: boolean;
  chkI: boolean;
  chkD: boolean;
  chkC: boolean;
  chkCompleted: boolean;
  chkCanceled: boolean;

  currentStatus: string;
  invoiceNumber: string;


  auditObj = new CoreAudit();
    outboxDetail: Outbox;

  constructor(private alertService: AlertService, private accountService: AccountService, private configurations: ConfigurationService,
    private fowkesService: FowkesOnlineService, private sysproService: SysproService, private reportService: ReportService, private router: Router,
    private route: ActivatedRoute, private externalService: ExternalHTTPService, private http: HttpClient) {
    }


  ngOnInit() {
    let action = sessionStorage.getItem(localStorageActions.Customer);
    let quoteNumber = sessionStorage.getItem(localStorageActions.QuoteNumber);


    this.auditObj.referenceType = "WEBORDER";
    this.auditObj.actionType = "STATUS";
    this.auditObj.branch = this.branchCode;
    this.auditObj.applicationUser = this.accountService.currentUser.email;
    this.auditObj.actionedBy = this.accountService.currentUser.email;
    this.auditObj.groupKey = this.accountService.currentUser.id;

    this.selected = {
      startDate: moment().subtract(0, 'year').startOf('year'),
      endDate: moment().subtract(0, 'month').endOf('month')
    }


    this.quoteNumber = quoteNumber;
    this.id = action;

    this.setData();
    this.chkCompleted = true;
    this.chkCanceled = true;

    this.loadData();
  
  }

  private setData() {
    this.columns = [
      { prop: 'id', name: 'Order Id', width: 50 },
      { prop: 'created', name: 'Date', width: 100, cellTemplate: this.dateTemplate },
      { prop: 'placed_id', name: 'Waybill', width: 100, cellTemplate: this.selectTemplate },
      { prop: 'user_first_name', name: 'Customer Name', width: 150, cellTemplate: this.nameTemplate},
      { prop: 'grand_total', name: 'Total', width: 90, cellTemplate: this.totalTemplate, cellClass: "right" }, 
      { prop: 'internalStatus', name: 'Status', width: 100 },
      { prop: 'quoteNumber', name: 'Quote Number', width: 120, cellTemplate: this.quoteTemplate },
      { prop: 'salesOrder', name: 'SalesOrder', width: 100, cellTemplate: this.salesOrderTemplate },
      { prop: 'invoice', name: 'ITS', width: 100, cellTemplate: this.invoiceTemplate },
      { prop: 'user_email', name: 'Customer Email', width: 150 },
      { prop: 'user_contact_number', name: 'Customer Tel', width: 90 }
    ];


    this.columns.push({ name: 'Update Order Status', width: 80, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });


  }

  summaryTotal(cells: number[]): string {
    const filteredCells = cells.filter(cell => !!cell);
    return filteredCells.reduce((sum, cell) => (sum += cell), 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  ngAfterViewInit() {

    this.setData();
  }


  toggle() {
    this.show = true;
  }

  choosedDateTime(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  rangeClicked(e) {

    this.picker.renderRanges();

    this.selected = {
      startDate: this.picker.startDate,
      endDate: this.picker.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  searchDates(e) {

    this.selected = {
      startDate: e.startDate,
      endDate: e.endDate
    }

    this.filterChanged();
    this.show = false;
  }

  get loadCache(): boolean {
    return this.configurations.loadCache;
  }


  filterchkAll() {
    this.chkAll = !this.chkAll;
    this.filterChanged();
  }

    //   Orders To be Invoiced
  filterchkQ() {
    this.chkQ = !this.chkQ;
    this.filterChanged();
  }

  //    Orders still in Warehouse
  filterchkI() {
    this.chkI = !this.chkI;
    this.filterChanged();
  }

  // Orders Out for Delivery
  filterchkD() {
    this.chkD = !this.chkD;
    this.filterChanged();
  }

  // Collected By Customer
  filterchkC() {
    this.chkC = !this.chkC;
    this.filterChanged();
  }

  filterCompleted() {
    this.chkCompleted = !this.chkCompleted;
    this.filterChanged();
  }

  filterCancelled() {
    this.chkCanceled = !this.chkCanceled;
    this.filterChanged();
  }

  filterChanged() {

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.dateRows = this.rowsCache.filter(d => d.createDate >= startD && d.createDate <= endD);
      
    if (this.chkQ || this.chkI || this.chkD || this.chkC ) {
      this.rows = this.dateRows.filter(m =>
        (this.chkQ ? m.quoteNumber != "" && m.salesOrder == "" : '') ||
        (this.chkI ? (m.invoice != "" && m.internalStatus == "ITS") : '') ||
        (this.chkD ? (m.invoice != "" && m.internalStatus == "COURIER") : '') ||
        (this.chkC ? (m.invoice != "" && m.internalStatus == "COLLECTION") : ''));
    }
    else {
      this.rows = this.dateRows;
    }

    if (this.chkCompleted == true) {
      this.rows = this.rows.filter(f => f.internalStatus != "COMPLETED");
    }

    if (this.chkCanceled == true) {
      this.rows = this.rows.filter(f => f.internalStatus != "CANCELLED");
    }

    if (this.searchValue != "") {

      this.rows = this.rows.filter(r => Utilities.searchArray(this.searchValue,
        false, r.user_first_name, r.quoteNumber, r.user_last_name, r.salesOrder, r.internalStatus, r.created, r.placed_id, r.user_email));
    }
    

    var totalExcl = 0;
    var totalIncl = 0;
    var totalVat = 0;
    this.rows.forEach((order, index, x) => {
      (<any>order).index = index + 1;
      totalExcl += order.sub_total;
      totalIncl += order.grand_total;
      //totalVat += order.vat;
    });

    this.sumtotalExcluding = totalExcl;
    this.sumtotalIncluding = totalIncl;
    this.sumtotalVat = totalVat;

    this.rows = this.rows.sort((x, y) => x.createDate > y.createDate ? -1 : y.createDate > x.createDate ? 1 : 0);
  }


  filterOrders() {
    if (this.selectedStatus == "All") {
      this.rows = [...this.rowsCache];
    }
    else {
      if (this.selectedStatus == "Q") {
        this.rows = this.rowsCache.filter(r => r.quoteNumber != "" && r.salesOrder == "");
      }
      if (this.selectedStatus == "I") {
        this.rows = this.rowsCache.filter(r => r.invoice != "" && r.internalStatus == "ITS");
      }
      if (this.selectedStatus == "D") {
        this.rows = this.rowsCache.filter(r => r.invoice != "" && r.internalStatus == "COURIER");
      }
      if (this.selectedStatus == "C") {
        this.rows = this.rowsCache.filter(r => r.invoice != "" && r.internalStatus == "COMPLETE");
      }
    }

    var totalExcl = 0;
    var totalIncl = 0;
    var totalVat = 0;
    this.rows.forEach((order, index, x) => {
      (<any>order).index = index + 1;
      totalExcl += order.sub_total;
      totalIncl += order.grand_total;
      //totalVat += order.vat;
    });

    this.sumtotalExcluding = totalExcl;
    this.sumtotalIncluding = totalIncl;
    this.sumtotalVat = totalVat;
  }


  reset() {
    this.selectedStatus = "All";
    this.searchValue = "";
    this.chkQ = false;
    this.chkI = false;
    this.chkD = false;
    this.chkC = false;
    this.chkCompleted = true;
    this.chkCanceled = true;
    this.invoiceNumber = "";
    this.currentStatus = "";

    this.itsModalHide();
    this.weborderDetailModalHide();

    this.loadData();
  }

  loadData() {

    this.searchType = "customer";
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.sysproService.getAllWebOrders().subscribe(x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));

  }


  onSearchChanged(e: any) {

    let value = this.smartsearch;

    let startD: Date = new Date(this.selected.startDate.toString());
    let endD: Date = new Date(this.selected.endDate.toString());

    this.dateRows = this.rowsCache.filter(d => d.createDate >= startD && d.createDate <= endD);

    if (value.trim() != "") {
      if (this.chkQ || this.chkI || this.chkD || this.chkC) {
        this.rows = this.dateRows.filter(m =>
          (this.chkQ ? m.quoteNumber != "" && m.salesOrder == "" : '') ||
          (this.chkI ? (m.invoice != "" && m.internalStatus == "ITS") : '') ||
          (this.chkD ? (m.invoice != "" && m.internalStatus == "COURIER") : '') ||
          (this.chkC ? (m.invoice != "" && m.internalStatus == "COLLECTION") : ''));
      }

      this.rows = this.rows.filter(r => Utilities.searchArray(value,
        false, r.user_first_name, r.quoteNumber, r.user_last_name, r.salesOrder, r.internalStatus, r.created, r.placed_id, r.user_email));
      this.searchValue = value;
     

    }
    else {
      this.searchValue = "";
      if (this.chkQ || this.chkI || this.chkD || this.chkC) {
        this.rows = this.dateRows.filter(m =>
          (this.chkQ ? m.quoteNumber != "" && m.salesOrder == "" : '') ||
          (this.chkI ? (m.invoice != "" && m.internalStatus == "ITS") : '') ||
          (this.chkD ? (m.invoice != "" && m.internalStatus == "COURIER") : '') ||
          (this.chkC ? (m.invoice != "" && m.internalStatus == "COLLECTION") : ''));
      }
      else {

        this.rows = [...this.dateRows];
      }
    }


    if (this.chkCompleted == true) {
      this.rows = this.rows.filter(f => f.internalStatus != "COMPLETED");
    }
  }

  completeWebOrder(row: WebOrder) {
    this.invoiceNumber = row.invoice;

    if (this.invoiceNumber != "" && row.internalStatus != "COMPLETED") {
      this.updateWebOrder("COMPLETED");

    }
    else {

      this.alertService.showMessage('Warning', `Order can not be completed`, MessageSeverity.warn);
    }
  }


  cancelWebOrder(row: WebOrder) {
    this.invoiceNumber = row.invoice;
    if (this.invoiceNumber != "") {
      this.updateWebOrder("CANCELLED");
    }
  }

  saveWebOrder(row: WebOrder) {

    if (this.selectedStatus) {
      row.statusOverride = true;
      row.internalStatus = this.selectedStatus;

      this.auditObj.referenceNumber = row.placed_id;
      this.auditObj.actionOutput = this.selectedStatus;

      this.sysproService.UpdateWebOrderStatusWithComment(row.id, row).
        subscribe(x => this.onUpdateSuccessful(x), error => this.onDataLoadFailed(error));
    }
  }

  whatsappReminder(row: WebOrder) {

    if (row && row.user_contact_number) {

      this.outboxDetail = new Outbox();

      this.outboxDetail.toPhoneNumber = row.user_contact_number;
      this.outboxDetail.content = "Template";
      this.outboxDetail.headerMediaFileId = row.placed_id;
      this.outboxDetail.nextGenUser = this.accountService.currentUser.userName;

      this.sysproService.sendTemplateNoFile(this.outboxDetail.toPhoneNumber, this.outboxDetail.nextGenUser,
        this.outboxDetail.headerMediaFileId).subscribe(x => this.onWhatsappSentSuccesful(x, row), error => this.WhatsappSentFailed(error));
    }
   

  }

  WhatsappSentFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Error', `Something went wrong: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  onWhatsappSentSuccesful(x: any, row: WebOrder): void {
    console.log(x);

    const currentDate = new Date(); // Gets the current date and time
    // Formats the current date and time as a string, e.g., "2024-01-25 14:30"
    const dateTimeStr = currentDate.toISOString().replace('T', ' ').substring(0, 16);
    // Appends the message and the current date and time to the internal comment
    row.internalComment += `Reminder ${x.message} on ${dateTimeStr}`;

    this.sysproService.UpdateWebOrderStatusWithComment(row.id, row).
      subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showStickyMessage('', `"${x.message}"`, MessageSeverity.info);
        this.postAudit();
        this.reset();

      }, error => this.onDataLoadFailed(error));   
  }

  updateWebOrder(status: string) {

    if (status != "") {
      this.auditObj.referenceNumber = this.invoiceNumber;
      this.auditObj.actionOutput = status;
      this.sysproService.updateWebOrderStatus(this.invoiceNumber, status).
        subscribe(x => this.onUpdateSuccessful(x), error => this.onDataLoadFailed(error));
    }
  }


  onUpdateSuccessful(x: any): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.alertService.showMessage('Success', `Order Updated`, MessageSeverity.success);
    this.postAudit();
    this.reset();
  }

  private postAudit() {
    this.sysproService.postCoreAudit(this.auditObj).subscribe(x => console.log(x));
  }

  viewOrder(row: WebOrder) {

    this.webOrder = new WebOrder();
    this.webOrder = row;

    this.currentStatus = row.internalStatus;
    this.invoiceNumber = row.invoice;
    this.selectedStatus = row.internalStatus;
   // this.sysproService.getWebOrderLineInfo(row.id).subscribe(x => this.onLinesSuccesfull(x), error => this.onDataLoadFailed(error));

    this.auditObj.referenceNumber = row.invoice;
    this.auditObj.actionInput = this.currentStatus;

    this.sysproService.getWebOrderPaymentInfo(row.id).subscribe(x => this.onPaymentsSuccesfull(x), error => this.onDataLoadFailed(error));



  }
  onPaymentsSuccesfull(x: WebPayment[]): void {
    var test = x[0];

    this.webOrderPayment = x;

    if (!!this.webOrder.id) {
      if (!this.weborderDetailModal) {
        setTimeout(() => {
          if (this.weborderDetailModal) {
            this.weborderDetailModal.show();
          }
        });
      }
      else {
        this.weborderDetailModal.show();
      }
    }

  }


    tokenSuccess(x: any): void {
      var test = x;
  }

    onLinesSuccesfull(x: WebLineItem[]): void {
      var test = x;


  }


  openDPD(waybill: string) {
    if (!!waybill && waybill != "") {
      window.open('https://www.dpd.com/za/en/receiving-parcels/track-my-parcel/tracking-details/?' + waybill, "_blank");
    }
  }

  weborderDetailModalHide() {
    this.webOrder = null;
    if (this.weborderDetailModal) {
      this.weborderDetailModal.hide();
    }
  }


  viewQuote(row: WebOrder) {
    this.setLocalCustomer("FBWEB", row.quoteNumber);
    this.router.navigate(['../../quotes'], { fragment: 'quote', queryParams: { customer: "FBWEB" } });
  }


  previewQuote(row: WebOrder) {
    this.currentStatus = row.internalStatus;

    this.invoiceNumber = row.invoice;
    this.setLocalCustomer("FBWEB", row.quoteNumber);

    if (!!this.selectedQuote) {
      if (!this.quotesModal) {
        setTimeout(() => {
          if (this.quotesModal) {
            this.quotesModal.show();
          }
        });
      }
      else {
        this.quotesModal.show();
      }
    }
  }

  quotesModalHide() {
    this.selectedQuote = null;
    this.quotesModal.hide();
  }

  gotToQuote() {
    this.router.navigate(['../../quotes'], { fragment: 'quote', queryParams: { customer: "FBWEB" } });
  }

  previewSalesOrder(row: WebOrder) {

    this.currentStatus = row.internalStatus;
    this.invoiceNumber = row.invoice;

    this.selectedSalesOrder = row.salesOrder;
    if (sessionStorage.getItem(localStorageActions.Customer) != "FBWEB") {
      sessionStorage.setItem(localStorageActions.Customer, "FBWEB");
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != row.salesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrder);
    }

    if (!!this.selectedSalesOrder) {
      if (!this.salesOrderModal) {
        setTimeout(() => {
          if (this.salesOrderModal) {
            this.salesOrderModal.show();
          }
        });
      }
      else {
        this.salesOrderModal.show();
      }
    }
  }

  salesOrderModalHide() {
    this.selectedSalesOrder = null;
    this.salesOrderModal.hide();
  }


  viewSalesOrder() {
  
    this.router.navigate(['../../salesorders'], { fragment: 'view', queryParams: { customer: "FBWEB" } });
  }


  previewITS(row: WebOrder) {

    this.selectedInvoice = row.invoice;
    this.currentStatus = row.internalStatus;
    this.invoiceNumber = row.invoice;

    if (sessionStorage.getItem(localStorageActions.Customer) != "FBWEB") {
      sessionStorage.setItem(localStorageActions.Customer, "FBWEB");
    }
    if (sessionStorage.getItem(localStorageActions.SalesOrderNumber) != row.salesOrder) {
      sessionStorage.setItem(localStorageActions.SalesOrderNumber, row.salesOrder);
    }

    if (sessionStorage.getItem(localStorageActions.ITSInvoiceNumber) != row.invoice) {
      sessionStorage.setItem(localStorageActions.ITSInvoiceNumber, row.invoice);
    }

    if (!!this.selectedInvoice) {
      if (!this.itsModal) {
        setTimeout(() => {
          if (this.itsModal) {
            this.itsModal.show();
          }
        });
      }
      else {
        this.itsModal.show();
      }
    }
  }

  itsModalHide() {
    this.selectedInvoice = null;
    if (this.itsModal) {
      this.itsModal.hide();
    }
  }


  gotToITS() {

    this.router.navigate(['../../reports'], { fragment: 'invoicestatus', queryParams: { customer: "FBWEB" } });
  }

  setLocalCustomer(customer: string, quoteNumber: string) {

    this.selectedCustomer = customer;
    this.selectedQuote = quoteNumber;
    if (sessionStorage.getItem(localStorageActions.QuoteNumber) != quoteNumber) {
      sessionStorage.setItem(localStorageActions.QuoteNumber, quoteNumber);
    }
    if (sessionStorage.getItem(localStorageActions.Customer) != customer) {
      sessionStorage.setItem(localStorageActions.Customer, customer);
    }
    if (this.searchValue && this.searchValue.length > 1) {
      
    }
    else {
      sessionStorage.removeItem(localStorageActions.SearchValue);
    }
  }


  specificSearch() {
    if (this.searchType && this.searchText != "") {

      this.alertService.startLoadingMessage();
      this.loadingIndicator = true;
     
        //this.sysproService.getQuoteNumberSearchByCriteria(this.searchType, this.searchText).subscribe(
        //  x => this.onSearchSuccesful(x), error => this.onDataLoadFailed(error));
      
    }
    else {
      this.alertService.showStickyMessage('Empty search', 'Please enter a value to search on',
        MessageSeverity.warn);
    }
  }


  onSpecificSearchSuccesful(x: QuoteList[]): void {

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.searchResults = x;

  }
  onSearchSuccesful(x: WebOrder[]): void {

      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.weborders = x;
      var totalExcl = 0;
      var totalIncl = 0;
      var totalVat = 0;

      this.rowsInclCancelled = [...x];

      // Group orders by user and calculate the sequence number for each order per user
      const groupedOrders = x.reduce((acc, curr) => {
        acc[curr.user_email] = acc[curr.user_email] || [];
        acc[curr.user_email].push(curr);
        return acc;
      }, {});

      // Flatten the grouped orders and set sequence numbers
      const flattenedOrders = [];
      Object.keys(groupedOrders).forEach(email => {
        groupedOrders[email].sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
        groupedOrders[email].forEach((order, index) => {
          order.sequenceNumber = index + 1;
          flattenedOrders.push(order);
        });
      });

      x = flattenedOrders;

      x.forEach((order, index, x) => {
        (<any>order).index = index + 1;
        const date = new Date(order.created);
        order.createDate = date;
        order.created = moment(order.created).format('YYYY-MM-DD');
        totalExcl += order.sub_total;
        totalIncl += order.grand_total;
        //totalVat += order.vat;
      });


      this.rowsCache = [...x];         

      this.rows = this.dateRows; //.slice(0, 18);

      this.sumtotalExcluding = totalExcl;
      this.sumtotalIncluding = totalIncl;
      this.sumtotalVat = totalVat;

      this.closeIfPODReceived();

      this.filterChanged();
  }

  closeIfPODReceived() {
    // Get today's date
    const today = new Date();
    // Get the first day of the current month
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

      this.rowsCache.forEach((order, index, x) => {

        if (order.internalStatus == "COLLECTION") {
          //Check if POD has been received
          this.sysproService.getInvoiceTrackingStatus(order.salesOrder, order.invoice).
            subscribe(result => {
              if (result[0]) {
                var its = result[0];
                if (its.podReceived == 'A') {
                  this.invoiceNumber = order.invoice;
                  order.internalStatus = "COMPLETED";
                  order.displayStatus = "COMPLETED";
                  order.internalComment = order.internalComment + " - Updated by system : POD Received on " + new Date().toISOString().slice(0, 10);
                  this.selectedStatus = order.internalStatus;
                  this.saveWebOrder(order);
                }
                else {
                  //Check if Collection Notification has been sent
                  if (!order.internalComment.includes("Message Sent to") && order.createDate >= firstDayOfMonth) {
                    //Send Whatsapp Reminder
                    this.whatsappReminder(order);
                  }
                }
              }
            });
          
        }
      });
  }


  get branchCode(): string {
    return this.configurations.branchCode;
  }
    

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve details from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }
  

  results() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: ["Id", "Date", "Waybill / PO", "Customer", "Invoice", "Status", "Total", "QuoteNumber",  "SalesOrder", "CustomerEmail", "Contact Number"]
    };

    var filename = "WebOrders";
    var selectRows = [...this.rows];
    var exportRows = [];
    for (var row of selectRows) {
      let date = new Date(row.created).toISOString().split('T')[0];
      exportRows.push({
        Id: row.id, Date: date, Waybill: row.placed_id, Customer: row.user_first_name + " " + row.user_last_name, Invoice: row.invoice, Status: row.internalStatus,
        Total: row.grand_total, QuoteNumber: row.quoteNumber, SalesOrder: row.salesOrder, CustomerEmail: row.user_email, CustomerTel: "Tel:  " + row.user_contact_number.toString()
      });
    }

    return new ngxCsv(exportRows, filename, options);
  }


}
